const breakpoints = ["576px", "768px", "992px", "1200px", "1400px"];

export const theme = {
  colors: {
    // primary: "#0168fa",
    primary: "#ff7272",
    secondary: "#5f6d88",
    success: "#10b759",
    info: "#00b8d4",
    warning: "#ffc107",
    danger: "#dc3545",
    light: "#e5e9f2",
    dark: "#3b4863",
    text: "#001737",
    text2: "#1b2e4b",
    text3: "#8392a5",
    text4: "#c0ccda",
    heading: "#001737",
    link: "#001737",
    background: "#ffffff",
    hover: "#0168fa",
    white: "#ffffff",
    black: "#000000",
    close: "#1b2e4b",
    border: "#485e9029",
    gray50: "#fafbfc",
    gray100: "#f4f5f8",
    gray200: "#e3e7ed",
    gray300: "#cdd4e0",
    gray400: "#b4bdce",
    gray500: "#97a3b9",
    gray600: "#7987a1",
    gray700: "#596882",
    gray800: "#3b4863",
    gray900: "#1c273c",
    gray950: "#637388",
    whisper: "#f5f6fa",
    malibu: "#69b2f8",
    tropical: "#d1e6fa",
    pink: "#f10075",
    bayoux: "#49597b",
    athens: "#e9ecf1",
    orange: "#fd7e14",
    cyan: "#17a2b8",
    teal: "#00cccc",
    indigo: "#5b47fb",
    vulcan: "#0f1520",
    litecoin: "#325a98",
    lightblue: "#a5d7fd",
    brand2: "#042893",
    shuttle: "#5c6c7f",
    science: "#063cdd",
    facebook: "#4064ac",
    twitter: "#00a7e6",
    shuttle2: "#566476",
    lilac: "#f8f9fc",
    catskill: "#eef0f7",
    gulf: "#031a61",
  },
  fontSize: {
    body: "0.875rem",
    h1: ["2.1875rem", "2.1875rem", "2.1875rem", "2.1875rem"],
    h2: ["1.75rem", "1.75rem", "1.75rem"],
    h3: ["1.53125rem", "1.53125rem"],
    h4: ["1.3125rem", "1.3125rem"],
    h5: ["1.09375rem", "1.09375rem"],
    h6: ["0.875rem", "0.875rem"],
  },
  fonts: {
    body: `'IBM Plex Sans', sans-serif`,
    heading: `'IBM Plex Sans', sans-serif`,
    interUi: `-apple-system, BlinkMacSystemFont, "Inter UI", Roboto, sans-serif`,
    rubik: `'Rubik', sans-serif`,
  },
  fontWeights: {
    body: 400,
    heading: 500,
  },
  lineHeights: {
    body: 1.5,
    heading: 1.25,
  },
  radii: {
    sm: "3px",
    md: "6px",
    lg: "8px",
    rounded: "4px",
    circle: "50%",
    pill: "500px",
  },
  shadows: {
    default: "0 0 12px 3px rgba(0, 0, 0, 0.06)",
    sm: "0px -1px 1px 0px rgba(0,0,0, .2)",
    lg: "0 1rem 3rem rgba(0, 0, 0, .175)",
    input: "0 0 0 0.2rem rgb(1 104 250 / 25%)",
  },
  breakpoints: [...breakpoints],
  transition: "all 0.4s ease 0s",
  anchor: {
    primary: {
      color: "white",
      bg: "red",
    },
  },
};
